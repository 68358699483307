import * as React from 'react';

import CommonLayout from '../components/home/commonLayout';
import Layout from '../components/layout';
import PharmCorpHeading from '../components/typography/heading';
import SEOTag from '../components/seo/seo';
import { navigate } from 'gatsby';

// markup
const TermsOfUsePage = () => {
  React.useEffect(() => {
    navigate('../terms-of-use.pdf');
  }, []);

  return (
    <Layout>
      <SEOTag title='Terms of Use | Pharmcorps' />
      <CommonLayout>
        <PharmCorpHeading
          lightText='Terms'
          boldText='of Use'
          extraClassNames='my-4'
          underLined={true}
        />
        <div className='h-2 mx-auto mb-10'></div>
        <div className='pb-10 text-gray-600'></div>
      </CommonLayout>
    </Layout>
  );
};

export default TermsOfUsePage;
